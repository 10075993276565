import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
  productIdAmazonAdvertisingBrandMetrics,
  productIdAmazonBrandStoreInsights,
  productIdAmazonAdvertisingMarketStream,
  productIdAmazonAdvertisingRecommendations,
  productIdAmazonSponsoredAdsV2, productIdAmazonSponsoredAdsV3, productIdAmazonAttribution,
  productIdAmazonDsp
} from '../product-ids';


export const integrationAmazonSponsoredAdsV2: Integration = {
  productId: productIdAmazonSponsoredAdsV2,
  subProductId: "default",
  enabled: false,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Sponsored Ads (V2)',
  badge: 'none',
  type: 'source',
  sortOrder: 1201,
  tagLine: 'Sponsored Brand & Display reporting connector',
  routerLink: '/wizards/amazon-sponsored-ads-v2',
  logoPath: vendors.amazonAdvertising,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 14,
  tableWeight: 0.7,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'ads.amazon.com/reporting',
    uri: 'https://advertising.amazon.com/API/docs/en-us/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: 'Amazon Advertising',
    upstreamSourceProduct: 'Amazon Advertising',
    workerName: 'amzadvertising',
    pathPrefix: 'amazon-advertising-insights',
    queryKey: 'amazon-advertising',
  }  

};

export const integrationAmazonSponsoredAdsV3: Integration = {
  productId: productIdAmazonSponsoredAdsV3,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Sponsored Ads (V3)',
  badge: 'none',
  type: 'source',
  sortOrder: 1201,
  tagLine: 'Sponsored Product, Brand & Display reporting connector (New)',
  routerLink: '/wizards/amazon-sponsored-ads-v3',
  logoPath: vendors.amazonAdvertising,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 14,
  tableWeight: 0.7,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'ads.amazon.com/reporting',
    uri: 'https://advertising.amazon.com/API/docs/en-us/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: 'Amazon Advertising',
    upstreamSourceProduct: 'Amazon Advertising',
    workerName: 'amzn_ads',
    pathPrefix: 'amazon-ads',
    queryKey: 'amazon-advertising',
  }  
};

export const integrationAmazonAdvertisingBrandMetrics: Integration = {
  productId: productIdAmazonAdvertisingBrandMetrics,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Advertising Brand Metrics',
  badge: 'none',
  type: 'source',
  sortOrder: 1201,
  tagLine: 'Sponsored Products API reporting insights connector',
  routerLink: '/wizards/amazon-advertising-brand-metrics',
  logoPath: vendors.amazonAdvertising,
  frequency: 'Hourly',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 14,
  tableWeight: 0.2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'ads.amazon.com/reporting',
    uri: 'https://advertising.amazon.com/API/docs/en-us/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: 'Amazon Advertising',
    upstreamSourceProduct: 'Amazon Advertising',
    workerName: 'amzn_brand',
    pathPrefix: 'amazon-brand',
    queryKey: 'amazon-advertising',
  }  
};

export const integrationAmazonBrandStoreInsights: Integration = {
  productId: productIdAmazonBrandStoreInsights,
  subProductId: "default",
  primary: true,  
  enabled: true,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Brand Store Insights',
  badge: 'none',
  type: 'source',
  sortOrder: 1201,
  tagLine: 'Amazon brand store impressions, clicks, sales, traffic, and more',
  routerLink: '/wizards/amazon-brand-store-insights',
  logoPath: vendors.amazonAdvertising,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 14,
  tableWeight: 0.3,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'ads.amazon.com/stores',
    uri: 'https://advertising.amazon.com/API/docs/en-us/stores/open-api'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: 'Amazon Advertising',
    upstreamSourceProduct: 'Amazon Advertising',
    workerName: 'amzn_brand_stores',
    pathPrefix: 'amazon-brand-stores',
    queryKey: 'amazon-advertising',
  }  
};

export const integrationAmazonAttribution: Integration = {
  productId: productIdAmazonAttribution,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Attribution',
  badge: 'none',
  type: 'source',
  sortOrder: 100,
  tagLine: 'Google Ads, Facebook, Microsoft Ads...driving purchase activity on Amazon',
  routerLink: '/wizards/amazon-attribution',
  logoPath: vendors.amazonAdvertising,
  frequency: 'Daily',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 14,
  tableWeight: 0.2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'ads.amazon.com/attribution',
    uri: 'https://advertising.amazon.com/API/docs/en-us/amazon-attribution/overview'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: 'Amazon Advertising',
    upstreamSourceProduct: 'Amazon Advertising',
    workerName: 'amzn_attribution',
    pathPrefix: 'amazon-attribution',
    queryKey: 'amazon-advertising',
  }  
};

export const integrationAmazonDsp: Integration = {
  productId: productIdAmazonDsp,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'DSP',
  badge: 'none',
  type: 'source',
  sortOrder: 200,
  keywords: [
    'amazon'
  ],
  tagLine: 'Programmatic advertising performance data for audiences on and off Amazon',
  routerLink: '/wizards/amazon-dsp',
  logoPath: vendors.amazonAdvertising,
  frequency: 'Daily',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 14,
  tableWeight: 0.2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'ads.amazon.com/dsp',
    uri: 'https://advertising.amazon.com/API/docs/en-us/dsp/overview'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'selection',
        'details',
        'advertiser',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ],
      managed: [
        'checklist',
        'identity',
        'selection',
        'managedAdvertiser',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: 'Amazon Advertising',
    upstreamSourceProduct: 'Amazon Advertising',
    workerName: 'amzn_dsp',
    pathPrefix: 'amazon-dsp',
    queryKey: 'amazon-advertising',
  }  
}

export const integrationAmazonAdvertisingMarketStream: Integration = {
  productId: productIdAmazonAdvertisingMarketStream,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Advertising Marketing Stream',
  badge: 'none',
  type: 'source',
  sortOrder: 1201,
  tagLine: 'Sponsored Ads and DSP performance metrics in near real time',
  routerLink: '/wizards/amazon-advertising-marketing-stream',
  logoPath: vendors.amazonAdvertising,
  frequency: 'Hourly',
  standardIntegration: false,
  premiumIntegration: true,
  opensourceIntegration: false,
  remoteIdentityTypeId: 14,
  tableWeight: 0,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'ads.amazon.com/reporting',
    uri: 'https://advertising.amazon.com/API/docs/en-us/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'aws',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: 'Amazon Advertising',
    upstreamSourceProduct: 'Amazon Advertising',
    workerName: 'amzn_stream',
    pathPrefix: 'amazon-marketing-stream',
    queryKey: 'amazon-advertising',
  }  
};

export const integrationAmazonAdvertisingRecommendations: Integration = {
  productId: productIdAmazonAdvertisingRecommendations,
  subProductId: "default",
  enabled: true,
  primary: true,
  comingSoon: false,
  isConfigurable: true,
  brand: 'Amazon',
  name: 'Ads Recommendations',
  badge: 'none',
  type: 'source',
  sortOrder: 1201,
  tagLine: 'Optimization recommendations across Sponsored Advertising campaigns',
  routerLink: '/wizards/amazon-advertising-recommendations',
  logoPath: vendors.amazonAdvertising,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 14,
  tableWeight: 0.2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com/en/'
  },
  vendor: {
    text: 'ads.amazon.com/reporting',
    uri: 'https://advertising.amazon.com/API/docs/en-us/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'datasets',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false,
  gptKnowledge: {
    upstreamSource: 'Amazon Advertising',
    upstreamSourceProduct: 'Amazon Advertising',
    workerName: 'amzn_tactical',
    pathPrefix: 'amazon-tactical',
    queryKey: 'amazon-advertising',
  }  
};
