import { ProductTableService } from '../../../core/services/product-table.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Integration } from '../../models/integration.model';
import { AppState } from 'src/app/core/store/reducers';
import { Store } from '@ngrx/store';
import { modalsClose, modalsOpen, modalsUpdate } from 'src/app/core/store/actions/modals.action';
import { delay } from 'src/app/core/functions/utility';

@Component({
  selector: 'app-product-table-selector',
  templateUrl: './product-table-selector.component.html',
  styleUrls: ['./product-table-selector.component.scss']
})
export class ProductTableSelectorComponent implements OnInit, OnChanges {
  @Input() integration: Integration;
  @Input() preSelectedStageList: any = [];
  @Output() populateStageList = new EventEmitter<any[]>();

  selectedStageData: any = [];
  stageListData: any = [];

  numberOfPayloads: number = 0;
  maxPayloadSize: number = 10;
  totalScore: number = null;
  colorScheme = [
    { color: 'bg-success', label: 'Normal' },
    { color: 'bg-info', label: 'Low' },
    { color: 'bg-warning', label: 'High' },
    { color: 'bg-danger', label: 'Severe' }
  ];

  constructor(
    private productTableService: ProductTableService,
    private changeDetectorRef: ChangeDetectorRef,
    private store$: Store<AppState>

  ) { }

  async ngOnInit(): Promise<void> {
    if (this.integration.productId) {

      this.store$.dispatch(modalsOpen({
        modalType: 'progress',
        title: 'Requesting all the associated tables',
        message: 'Please wait while we requesting all the associated tables for you',
        progress: 0
      }));

      try {
        this.stageListData = await this.productTableService.getProductTableInformation(this.integration.productId);
        // Add isSelected field to each object (default set to false)
        this.stageListData = this.stageListData.map(item => ({
          ...item,
          isSelected: false
        }));

        this.store$.dispatch(modalsUpdate({
          modalType: 'progress',
          title: 'Requesting all the associated tables',
          message: 'Please wait while we requesting all the associated tables for you',
          progress: 50
        }));
  
        await delay(1000);
  
        this.store$.dispatch(modalsClose());

        // Initialize selectedItems with pre-selected items
        this.maxPayloadSize = this.stageListData.length;
        this.changeDetectorRef.detectChanges();
        this.calculateScore();
      }
      catch(error) {
        this.store$.dispatch(modalsClose());
      }
    }
  }

  ngOnChanges(): void {
  }


  getProgressPercentage(): number {
    const progress = (this.numberOfPayloads / this.maxPayloadSize) * 100;
    return Math.min(progress, 100); // Ensure progress does not exceed 100%
  }

  selectedStageList(data: any): void {
    if (data.isSelected) {
      // Add the item to the array if it doesn't already exist
      const exists = this.selectedStageData.some(item => item.stageId === data.stageId);
      if (!exists) {
        this.selectedStageData.push(data);
      }
    } else {
      // Remove the item from the fresh array if it exists
      this.selectedStageData = this.selectedStageData.filter(item => item.stageId !== data.stageId);
    }

    this.numberOfPayloads = this.selectedStageData.length;

    this.calculateScore();
    
    // Emit the updated array to the parent component
    this.populateStageList.emit(this.selectedStageData);
    this.changeDetectorRef.detectChanges();

  }

  calculateScore(): void {
    const productWeight = this.integration.tableWeight;
    const numberOfProductTables = this.stageListData.length;
    const numberOfSelectedTables = this.selectedStageData.length;

    const productTableWeight = ((100 / numberOfProductTables) * productWeight);
    const selectedProductTableWeight = (productTableWeight * numberOfSelectedTables);
    const score = 100 - selectedProductTableWeight;
    // Ensure Score is not negative    
    this.totalScore = Math.max(Math.round(score), 0);
    // this.totalScore = Math.max(Number(score.toFixed(2)), 0);
  }
}
