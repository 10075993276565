export const reportTypes = [
  {
    reportType: 'audience',
    reportTypeName: 'Audience',
    reportTypeLabel: 'Information about report type',
    dimensions: [
      {
        dimension: 'order',
        dimensionName: 'Order',
        dimensionLabel: 'Information about Order dimension'
      },
      {
        dimension: 'lineItem',
        dimensionName: 'Line Item'
      }
    ]
  },
  {
    reportType: 'audio_and_video',
    reportTypeName: 'Audio and Video',
    dimensions: [
      {
        dimension: 'campaign',
        dimensionName: 'Campaign'
      },
      {
        dimension: 'ad',
        dimensionName: 'Ad'
      },
      {
        dimension: 'creative',
        dimensionName: 'Creative'
      },
      {
        dimension: 'supplySource',
        dimensionName: 'Supply Source'
      },
      {
        dimension: 'content',
        dimensionName: 'Content'
      }
    ]
  },
  {
    reportType: 'campaign',
    reportTypeName: 'Campaign',
    dimensions: [
      {
        dimension: 'campaign',
        dimensionName: 'Campaign'
      },
      {
        dimension: 'ad',
        dimensionName: 'Ad'
      },
      {
        dimension: 'creative',
        dimensionName: 'Creative'
      }
    ]
  },
  {
    reportType: 'geo',
    reportTypeName: 'Geo',
    dimensions: [
      {
        dimension: 'order',
        dimensionName: 'Order'
      },
      {
        dimension: 'lineItem',
        dimensionName: 'Line Item'
      },
      {
        dimension: 'country',
        dimensionName: 'Country'
      },
      {
        dimension: 'region',
        dimensionName: 'Region'
      },
      {
        dimension: 'city',
        dimensionName: 'City'
      },
      {
        dimension: 'postalCode',
        dimensionName: 'Postal Code'
      },
      {
        dimension: 'dma',
        dimensionName: 'Dma'
      }
    ]
  },
  {
    reportType: 'inventory',
    reportTypeName: 'Inventory',
    dimensions: [
      {
        dimension: 'supplySource',
        dimensionName: 'Supply Source'
      },
      {
        dimension: 'site',
        dimensionName: 'Site'
      },
      {
        dimension: 'campaign',
        dimensionName: 'Campaign'
      },
      {
        dimension: 'creative',
        dimensionName: 'Creative'
      },
      {
        dimension: 'ad',
        dimensionName: 'Ad'
      },
      {
        dimension: 'placementSize',
        dimensionName: 'Placement Size'
      },
      {
        dimension: 'placement',
        dimensionName: 'Placement'
      },
      {
        dimension: 'deal',
        dimensionName: 'Deal'
      }
    ]
  },
  {
    reportType: 'product',
    reportTypeName: 'Product',
    dimensions: [
      {
        dimension: 'order',
        dimensionName: 'Order'
      },
      {
        dimension: 'lineItem',
        dimensionName: 'Line Item'
      }
    ]
  },
  {
    reportType: 'tech',
    reportTypeName: 'Tech',
    dimensions: [
      {
        dimension: 'order',
        dimensionName: 'Order'
      },
      {
        dimension: 'lineItem',
        dimensionName: 'Line Item'
      },
      {
        dimension: 'operatingSystem',
        dimensionName: 'Operating System'
      },
      {
        dimension: 'browserType',
        dimensionName: 'Browser Type'
      },
      {
        dimension: 'browserVersion',
        dimensionName: 'Browser Version'
      },
      {
        dimension: 'deviceType',
        dimensionName: 'Device Type'
      },
      {
        dimension: 'environmentType',
        dimensionName: 'Environment Type'
      }
    ]
  }
];