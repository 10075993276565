import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-product-table-checkbox',
  templateUrl: './product-table-checkbox.component.html',
  styleUrls: ['./product-table-checkbox.component.scss']
})
export class ProductTableCheckboxComponent implements OnInit {
  @Input() preSelectedStageList: any[];
  @Input() inputData: any;
  @Output() selectedStageList = new EventEmitter<any>();

  ngOnInit(): void {
    this.checkForPreSelection();
  }

  onCheckboxChange($event): void {
    const updatedSelection = {
      stageId: this.inputData.attributes.stage_id,
      name: this.inputData.attributes.name,
      isSelected: $event.target.checked
    };

    this.selectedStageList.emit(updatedSelection);
  }

  checkForPreSelection(): void {

    if(this.preSelectedStageList != null) {
      const isSelected = (this.preSelectedStageList.length === 0 || this.preSelectedStageList.includes(this.inputData.attributes.stage_id)) ? true : false

      if (isSelected) {
        const updatedSelection = {
          stageId: this.inputData.attributes.stage_id,
          name: this.inputData.attributes.name,
          isSelected: true
        };
        this.inputData.isSelected = true;
        this.selectedStageList.emit(updatedSelection);
      } 
    }
  }

}
