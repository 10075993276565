<!-- Checklist -->
<!-- <div class="checklist my-3">
  <div class="form-check" *ngFor="let item of checkListData">
    <label class="form-check-label" [for]="item.id">
      <input 
        class="form-check-input" 
        type="checkbox" 
        [id]="item.id" 
        [value]="item.id" 
        (change)="onCheckboxChange(item.id, $event.target.checked)">
      <span>
        {{ item.attributes.name }}
      </span>
    </label>
  </div>
</div> -->


<!-- Checklist -->
<div class="checklist my-3">
  <div class="row">
    <div class="col-6" *ngFor="let item of checkListData; let i = index">    
      <div class="form-check">
        <label class="form-check-label" [for]="item.id">
          <input 
            class="form-check-input" 
            type="checkbox" 
            [id]="item.id" 
            [value]="item.id" 
            (change)="onCheckboxChange(item.id, $event.target.checked)">
          <span>
            {{ item.attributes.name }}
          </span>
        </label>
      </div>
    </div>
  </div>
</div>