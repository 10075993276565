import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html'
})
export class CheckListComponent implements OnInit, AfterViewInit {
  @Input() cardData = [];
  @Input() selectedCards = [];
  @Input() allSelected: boolean;
  @Output() updateAdvertiserSelection = new EventEmitter<string>();
  @Output() updateAllSelection = new EventEmitter<boolean>();

  constructor(
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.changeDetection.detectChanges();
  }

  updateSelected(event): void {
    this.updateAdvertiserSelection.emit(event);
  }

  toggleSelectAll(): void {
    this.updateAllSelection.emit(!this.allSelected);
  }
}
