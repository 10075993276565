import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppState } from '../store/reducers';
import { Store } from '@ngrx/store';
import { modalsClose, modalsOpen, modalsUpdate } from '../store/actions/modals.action';
import { delay } from '../functions/utility';

@Injectable({
  providedIn: 'root'
})
export class ProductTableService {

  constructor(
    private http: HttpClient,
  ) { }

  async getProductTableInformation(productId: any): Promise<any> {
    const requestURL = environment.openbridgeApiUris.service + '/service/products/product/' + productId + '/payloads?stage_id__gte=1000';
    const response: any = await this.http.get(requestURL).toPromise();
    return response.data;
  }
}
