<ul class="list-group list-group-lg list-group-flush list my-n4 picklist ">
  <li class="list-group-item">
    <div class="row">
      <div class="col-9 align-self-center">
        <h4 class="mb-0 name text-white">
          Select all advertisers associated with your account
        </h4>
      </div>
      <div class="col-2 align-self-center text-end">
        <button type="button" *ngIf="!allSelected" (click)="toggleSelectAll()"
          class="btn btn-sm btn-white d-none d-md-inline-block">Select All</button>
        <button type="button" *ngIf="allSelected" (click)="toggleSelectAll()"
          class="btn btn-sm btn-danger d-none d-md-inline-block">Unselect All</button>
      </div>
      <div class="col-1"></div>
    </div>
  </li>
  <ng-container *ngFor="let data of cardData; index as idx;">
    <li class="list-group-item" appCheckListCard [selectedCards]="selectedCards" [cardData]="data"
      (updateAdvertiserSelection)="updateSelected($event)" [allSelected]="allSelected"></li>
  </ng-container>
</ul>