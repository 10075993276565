<div class="row" [formGroup]="parentForm">
  <div class="col-auto align-self-start">
    <!-- Avatar -->
    <div class="avatar avatar-lg">
      <img [src]="cardData.logoUri" [alt]="cardData.logoAltText" [title]="cardData.logoAltText"
        class="avatar-img rounded" (error)="imageServiceService.handleMissingImage($event)">
    </div>
  </div>
  <div class="col align-self-center ms-n2">
    <!-- Title -->
    <h4 class="mb-1 name text-white">
      <span [tooltip]="cardData.title" placement="auto">{{ cardData.title | truncate:[50, '...'] }}</span>
      <span *ngIf="cardData.new && controlName === 'identity'" class="badge bg-success-soft ms-2">New</span>
      <span *ngIf="cardData.external" class="badge bg-primary-soft ms-2">External</span>
    </h4>

    <ng-container *ngFor="let metaData of cardData.meta; index as metaIdx;">
      <!-- Text -->
      <ng-container *ngIf="metaData | objectHasKey: 'link' ; else nolink">
        <p class="card-text small text-muted mb-1"> {{ metaData.name }}: <a [href]="metaData.link" target="_blank"
            rel="noopener">{{ metaData.value }}</a>
      </ng-container>
      <ng-template #nolink>
        <p class="card-text small text-muted mb-1">
          {{ metaData.name }}:
          <span [tooltip]="metaData.value" placement="auto">
            <span [ngSwitch]="metaData.name">
              <span *ngSwitchCase="'Dataset ID'">
                {{ metaData.value | truncate:[50, '...'] }}
              </span>
              <span *ngSwitchDefault>
                {{ metaData.value | truncate:[230, '...'] }}
              </span>
            </span>
          </span>
        </p>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="cardData.children">
      <ng-container *ngFor="let child of cardData.children; inded as childIdx">
        <div class="row align-items-center mt-3">
          <div class="col-auto">
            <span class="avatar avatar-sm">
              <img [src]="child.logoUri" [alt]="child.logoAltText" [title]="child.logoAltText"
              class="avatar-img rounded" (error)="imageServiceService.handleMissingImage($event)">
            </span>
          </div>
          <div class="col ms-n2">
            <h4 class="mb-1">
              {{ child.name }}
            </h4>
            <p class="small text-muted mb-1">
              ID: {{ child.id | number:'8.0' | removeComma }}
            </p>
          </div>
        </div>

      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="(controlName !== 'identity' || (controlName === 'identity' && cardData.status && cardData.status === 'active')); else identity">
    <div class="col-auto align-self-center">
      <input type="radio" [attr.name]="controlName" style="display: none;" id="{{ cardData.id }}"
        value="{{ cardData.id }}" [formControlName]="controlName">
      <span class="mt-3 me-4 avatar-title rounded text-success h1 ng-tns-c100-0 radio-selected"><span
          class="fe fe-check ng-tns-c100-0"></span></span>
  
      <label for="{{ cardData.id }}" class="btn btn-sm btn-white d-none d-md-inline-block" id="radioBtn">
        Select
      </label>
    </div>
  </ng-container>
  <ng-template #identity>
    <div class="col-auto align-self-center">
      <div class="d-flex align-items-center">
        <span *ngIf="cardData.id == formControlValue"
        class="mt-3 me-4 avatar-title rounded text-success h1 ng-tns-c100-0 radio-selected"><span
          class="fe fe-check ng-tns-c100-0"></span></span>
        <button class="btn btn-sm btn-white d-none d-md-inline-block" type="button" (click)="reauthIdentity(cardData.id)">Reauthorize</button>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="controlName !== 'identity'; else identityState">
    <div class="col-auto align-self-center">
      <!-- Dropdown -->
      <p class="card-text small text-white">
        <span class="text-success">●</span> Active
      </p>
    </div>
  </ng-container>

  <ng-template #identityState>
    <div class="col-auto align-self-center">
      <!-- Dropdown -->
      <p class="card-text small text-white">

        <span [ngClass]="cardData.status === 'expired' ? 'text-danger': 'text-success'">●</span> {{ cardData.status | titlecase }}

        <!-- <span class="text-success">●</span> {{ cardData.status | titlecase }} -->
      </p>
    </div>
  </ng-template>
</div> <!-- / .row -->