import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-check-box-list',
  templateUrl: './check-box-list.component.html',
  styleUrls: []
})
export class CheckBoxListComponent {
  @Input() checkListData: { id: string, attributes: { name: string } }[] = [];

  @Output() selectionChange = new EventEmitter<number>();

  selectedItems: Set<string> = new Set();

  onCheckboxChange(id: string, isChecked: boolean): void {
    if (isChecked) {
      this.selectedItems.add(id);
    } else {
      this.selectedItems.delete(id);
    }
    this.selectionChange.emit(this.selectedItems.size);
  }
}
