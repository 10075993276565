<div class="form-check mt-3">
  <label class="form-check-label" [for]="inputData.attributes.stage_id">
    <input 
      class="form-check-input" 
      type="checkbox" 
      [id]="inputData.attributes.stage_id" 
      [value]="inputData.attributes.stage_id" 
      [checked]="inputData.isSelected"
      (change)="onCheckboxChange($event)">
    <span [tooltip]="inputData.attributes.name">
      {{ inputData.attributes.name | truncate:[35, '...'] }}
    </span>
  </label>
</div>
