import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';

@Component({
  selector: '[appCheckListCard]',
  templateUrl: './check-list-card.component.html'
})
export class CheckListCardComponent implements OnChanges, OnInit {

  @Input() allSelected: boolean;
  @Input() cardData;
  @Input() selectedCards: string[];
  @Output() updateAdvertiserSelection = new EventEmitter<string>();

  // tslint:disable-next-line: no-inferrable-types
  selected: boolean = false;

  constructor(
    public imageServiceService: ImageServiceService
  ) { }

  ngOnInit(): void {


    if (this.selectedCards instanceof Array && this.selectedCards.includes(this.cardData.id)) {
      this.selected = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCards) {
      if (changes.selectedCards.currentValue !== null && changes.selectedCards.currentValue.includes(this.cardData.id)) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    }
    if (changes.allSelected) {
      this.selected = this.allSelected;
    }
  }

  toggleSelection(): void {
    this.updateAdvertiserSelection.emit(this.cardData.id);
  }

}
